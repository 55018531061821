import './App.css';
import { Outlet, Link } from 'react-router-dom';
import { useEffect, useState } from 'react';

const CUSTOM_USER_UPDATED_EVENT = 'userUpdated';

function App() {
  const [user, setUser] = useState(null);

  useEffect(() => {
    window.addEventListener(CUSTOM_USER_UPDATED_EVENT, (event) => {
      if (event instanceof CustomEvent) {
        console.log('userUpdated event', event?.detail);
        setUser(event?.detail);
      }
    });
  }, []);

  return (
    <div className="app">
      <header className="app-header">
        <h1 className="app-header-title">I AM MYPET SAMPLE APPLICATION</h1>


      </header>

      <Outlet />
    </div>
  );
}

export default App;
