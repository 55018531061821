import { ChangeEvent, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Home.css';
/**
 * pet management is temporary while waiting to get pet from my-account
 */
interface Pet {
  id?: string;
  name: string;
  genderCode: string;
  age: number;
  speciesCode: string;
  breedCode: string;
  neutered: boolean;
  weight: number;
  lifestyle: string;
  petActivityCode: string;
}

const initialPetState: Pet = {
  id: '',
  name: 'milou',
  genderCode: 'male',
  age: 24,
  speciesCode: 'cat',
  breedCode: 'american_bobtail_shorthair',
  neutered: false,
  weight: 20,
  lifestyle: 'indoor',
  petActivityCode: 'low',
};

export const Home = () => {
  let navigate = useNavigate();
  const [pet, setPet] = useState<Pet>(initialPetState);

  const { id, name, genderCode, age, speciesCode, breedCode, neutered, weight, lifestyle, petActivityCode } = pet;

  const handleChangeInput = (event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = event.target;
    if (name === 'neutered') {
      setPet({ ...pet, [name]: value === 'true' });
    } else {
      setPet({ ...pet, [name]: value });
    }
  };

  const redirectToRecommendation = () => {
    const { id, ...petWithoutId } = pet;
    navigate('/product-finder/tree', {
      state: { pet: id ? pet : petWithoutId },
    });
  };

  return (
    <div>
      <p className="welcome">
        Welcome to a sample react application that integrates Product Finder and My Account as webcomponents altogether
      </p>
      <div>
        <div className="wrapper">
          <h4>OPTIONAL, TESTING : Redirect directly to PF recommendation page for the following pet :</h4>
          <code className="code form-grid">
            <label> id[Optionnal]:</label>
            <input value={id ?? ''} onChange={handleChangeInput} type="text" name="id" />

            <label> name:</label>
            <input value={name} onChange={handleChangeInput} type="text" name="name" />

            <label> genderCode:</label>
            <select onChange={handleChangeInput} name="genderCode" value={genderCode}>
              <option value="male">male</option>
              <option value="female">female</option>
            </select>

            <label> age:</label>
            <input value={age} onChange={handleChangeInput} type="number" name="age" />

            <label> speciesCode:</label>
            <select onChange={handleChangeInput} name="speciesCode" value={speciesCode}>
              <option value="cat">cat</option>
              <option value="dog">dog</option>
            </select>

            <label> breedCode:</label>
            <input value={breedCode} onChange={handleChangeInput} type="text" name="breedCode" />

            <label> neutered:</label>
            <select onChange={handleChangeInput} name="neutered" value={neutered.toString()}>
              <option value="true">true</option>
              <option value="false">false</option>
            </select>

            <label> weight:</label>
            <input value={weight} onChange={handleChangeInput} type="number" name="weight" />

            <label> lifestyle:</label>
            <input value={lifestyle} onChange={handleChangeInput} type="text" name="lifestyle" />

            <label> petActivityCode:</label>
            <select onChange={handleChangeInput} name="petActivityCode" value={petActivityCode}>
              <option value="low">low</option>
              <option value="moderate">moderate</option>
              <option value="high">high</option>
            </select>
          </code>
          <button className="button" onClick={redirectToRecommendation}>
            Click here !
          </button>
        </div>
      </div>
    </div>
  );
};
